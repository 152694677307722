import React, { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';
import './altHomePage.css'; // Assume custom CSS for styling

import { isMobile } from 'react-device-detect';
import { carouselInfo } from './imageSelectionConstants.js';
import { reviews, ResponsiveCards } from './ReviewSwiper.js';
import { FaCreditCard, FaShoppingCart, FaPhotoVideo, FaChevronCircleUp } from 'react-icons/fa'; // Import shopping cart icon
import { v4 as uuidv4 } from 'uuid';
// Our own defined components.
import Banner from './Banner.js';
import FadingBanner from './FadingBanner.js';
import MediaGallery from './MediaGallery.js';
import SizeSelector from './SizeSelector.js';
import CopiesSelector from './NumCopiesSelector.js';
import Guarantees from './Guarantees.js';
import HoverInfoIcon from './InfoHoverWidget.js'
import { OrderContext } from './OrderContext';
import Tag from './Tag.js';
import { log, isProd } from './Logger.js';
import applePayLogo from './images/applepay.png';
import gpayLogo from './images/gpay.png';
import cards from './images/cards.png';

import { useNavigate } from 'react-router-dom';

// Defined constants and helper functions
import * as Constants from './Constants.js';
import * as Utils from './utils.js';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { logEvent } from "firebase/analytics";
import { analytics, storage } from "./firebaseConfig.js";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const AltHomePage = () => {
  const navigate = useNavigate();


  const [trueHeight, setTrueHeight] = React.useState(window.innerHeight);
  const [selectedSize, setSelectedSize] = useState('5x5');
  const [selectedStyleIndex, setSelectedStyleIndex] = useState(0);
  const [selectedCopies, setSelectedCopies] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [imageUploadInProgress, setImageUploadInProgress] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [specialRequests, setSpecialRequests] = useState('');
  const [selectedPackStyles, setSelectedPackStyles] = useState(['', '', '']); // Track selected styles for 3 Style Pack
  const [infographicOpen, setInfographicOpen] = useState(false);


  const [showCheckout, setShowCheckout] = useState(false);
  const modalRef = useRef(null);
  const howItWorksModalRef = useRef(null);

  const { orders, addOrder } = useContext(OrderContext);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setInfographicOpen(false);
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      localStorage.setItem('hasVisited', 'true'); // Mark the user as having visited
      setTimeout(toggleInfographicModal, 8000);
    }
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      setTrueHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (infographicOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [infographicOpen]);

  const fetchClientSecret = useCallback(() => {
    let orders_to_send = orders;
    if (uploadedImage.length !== 0) {
      orders_to_send = orders_to_send.concat(makeOrder());
    }
    log("Orders being sent:", orders_to_send);

    return fetch(Constants.SERVER_BASE_URL + "/create-checkout-session", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ orders: orders_to_send }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            const errorMessage = data.error || `Request failed with status ${res.status}`;
            throw new Error(errorMessage);
          });
        }
        return res.json();
      })
      .then((data) => data.clientSecret)
      .catch((error) => {
        console.error('Error creating checkout session:', error.message || error);
        alert(error.message || 'An unexpected error occurred.');
        throw error;
      });
  }, [orders, uploadedImage]);
  const options = useMemo(() => ({ fetchClientSecret }), [fetchClientSecret]);

  const handleCheckoutClick = () => {
    logEvent(analytics, "checkout_clicked");
    if (orders.length === 0 && uploadedImage.length === 0) {
      alert("Please add an item to the cart before checking out.");
      return;
    }
    if (uploadedImage.length > 0 && !isValidOrder()) {
      return;
    }
    setShowCheckout(true);
    modalRef.current?.showModal();
  };

  const handleCloseModal = () => {
    setShowCheckout(false);
    modalRef.current?.close();
  };

  const handleOpenProofModal = () => {
    logEvent(analytics, "how_it_works_modal_opened");
    howItWorksModalRef.current?.showModal();
  };
  const handleCloseProofModal = () => {
    howItWorksModalRef.current?.close();
  };

  const handleOutsideClick = useCallback((event) => {
    if (!event.target.closest('.dropdown')) {
      setIsDropdownOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isProd() && window.fbq) {
      window.fbq('track', 'PageView');
    }
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isDropdownOpen]);

  const handleCopiesChange = (copies) => {
    setSelectedCopies(copies);
  };

  const handleSelectedStyle = (index) => {
    logEvent(analytics, "style_selected:  " + carouselInfo[index].alt);
    setSelectedStyleIndex(index);
  }

  const handlePackStyleChange = (index, value) => {
    const updatedStyles = [...selectedPackStyles];
    updatedStyles[index] = value;
    setSelectedPackStyles(updatedStyles);
  };



  // Upload image to Firebase Storage
  const handleImageChange = (event, is_before = false) => {
    setImageUploadInProgress(true);

    const files = Array.from(event.target.files);

    const previews = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve({ src: reader.result, file });
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    // Wait for all previews to be generated
    Promise.all(previews)
      .then((images) => {
        const uploadPromises = [images[0]].map(({ file, src }) => {
          // Create a reference in Firebase Storage
          const storageRef = ref(storage, `images/${uuidv4()}`);

          // Start the upload
          return new Promise((resolve, reject) => {
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                console.log(`Upload progress for ${file.name}: ${progress}%`);
              },
              (error) => {
                console.error(`Error uploading ${file.name}:`, error);
                reject(error);
              },
              () => {
                // Get download URL after upload completes
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    log(`File available at: ${downloadURL}`);
                    resolve({ src, name: file.name, url: downloadURL });
                  })
                  .catch(reject);
              }
            );
          });
        });

        // Wait for all uploads to finish
        return Promise.all(uploadPromises);
      })
      .then((uploadedImages) => {
        // Process uploaded images, e.g., set them in state
        log("Uploaded Images: " + uploadedImages);

        // Example: Store the uploaded image URLs
        setUploadedImage(uploadedImages.map((img) => img.url));

        setImageUploadInProgress(false);
      })
      .catch((error) => {
        console.error("Error handling image uploads:", error);
        setImageUploadInProgress(false);
      });
  };

  const makeOrder = () => {
    const style = selectedStyleIndex === 0 ? selectedPackStyles.join('//') : carouselInfo[selectedStyleIndex].alt;
    return {
      id: uuidv4(),
      imagePairs: [uploadedImage[0], null],
      mode: Constants.LITU,
      size: selectedSize,
      copies: selectedCopies,
      specialRequest: specialRequests,
      style: style,
    }
  };

  const isValidOrder = () => {
    if (uploadedImage.length === 0) {
      alert('Please upload an image before adding to cart.');
      return false;
    }

    log("Selected Pack Styles:", selectedPackStyles);
    return true;
  }
  const handleAddToCart = async () => {
    // The user
    if (!isValidOrder()) {
      return;
    }

    log("Selected Pack Styles:", selectedPackStyles);

    if (window.fbq && isProd()) {
      window.fbq('track', 'AddToCart');
    }

    try {
      // Add the order and await its completion
      await addOrder(makeOrder());
      window.location.reload();
    } catch (error) {
      console.error('Failed to add item to cart:', error);
      alert('There was an error adding the item to your cart. Please try again.');
    }
  };

  const toggleInfographicModal = () => {
    setInfographicOpen(!infographicOpen);
  };



  const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);

  return (
    <div className="flex flex-col">
      <div className="product-page bg-container min-h-screen min-w-screen">
        <Banner mb={false} handleCheckoutClick={handleCheckoutClick} />
        <FadingBanner
          messages={[
            'Individually curated prints for any occasion. 🎨',
            <span><b className="mr-8">Holiday sale</b>Up to 43% off each individual order 🎁</span>,
            <span>
              Questions? Check out our <a href="/faq" style={{ textDecoration: 'underline', color: 'blue' }}>FAQ</a> 🔍
            </span>,
            'Get $10 off each additional copy you order 🎉',
          ]}
          duration={6000} // Each message is visible for 7 seconds
        />
        <MediaGallery activeMediaIndex={selectedStyleIndex} />
        <div className="product-info bg-white text-off-black border border-gray-300 shadow-lg rounded-lg p-6">
          <div className="flex flex-col">
            <text className="font-helv-neue font-bold tracking-wider text-3xl">{carouselInfo[selectedStyleIndex].title.toUpperCase()}</text>
            <span className="font-helv-neue mt-2">{carouselInfo[selectedStyleIndex].description}</span>
            {/* Tag Section */}
            <div className="flex flex-wrap mt-2">
              {carouselInfo[selectedStyleIndex].tags?.map((tag, index) => (
                <Tag key={index} text={tag} />
              ))}
            </div>
          </div>
          <>
            <p className="text-sm text-green-600 mt-4">
              {Math.round(20 / (Constants.getPrice(selectedSize) - Constants.PROOF_FEE + 20) * 100)}% off!
            </p>
            <p className="flex flex-row items-baseline flex-wrap">
              <span className="current-price font-baloo text-3xl">
                ${Constants.getPrice(selectedSize)}
              </span>
              <span className="line-through text-black-600 text-lg mx-1 font-baloo">
                ${(parseFloat(Constants.getPrice(selectedSize)) + 20.0).toFixed(2)}
              </span>
              <span className="font-helv-neue text-xs align-bottom whitespace-nowrap">
                + shipping
              </span>
            </p>
          </>

          {/* <SizeSelector
          sizes={['Pay now', 'Pay partially']}
          onSizeChange={setOrderMethod}
          defaultSize='Pay now'
          title={null}
        /> */}

          {/* Styles Section */}
          <div className="styles-section">
            <div className="text-xl tracking-wide font-semibold font-helv-neue mt-6">Styles</div>
            <div className="style-options flex flex-wrap rounded mt-2">
              {carouselInfo.map((item, index) => (
                <div key={index} className="style-option relative group">
                  <img
                    src={item.thumbnail ? item.thumbnail : item.src}
                    alt={item.alt}
                    className={`style-image object-cover border-2 p-2 w-[80px] h-[80px]  ${selectedStyleIndex === index ? "border-blue-300 border-2" : ""}  
                  hover:border-blue-300 hover:border-2`}
                    onClick={() => handleSelectedStyle(index)}
                  />
                  <div className="absolute bg-less-off-black text-white text-xs p-1 rounded shadow-md hidden group-hover:block z-10 whitespace-nowrap" style={{ left: '50%', transform: 'translateX(20%), translateY(40%)' }}>
                    {item.alt}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {selectedStyleIndex === -1 && (
            <div className="style-pack-section mt-4 font-helv-neue flex flex-col">
              <h3 className="text-lg font-semibold">Select up to three styles:</h3>
              {[0, 1, 2].map((index) => (
                <select
                  key={index}
                  value={selectedPackStyles[index] || ""} // Set default if no value is selected
                  onChange={(e) => handlePackStyleChange(index, e.target.value)}
                  className="font-helv-neue mt-2 p-2 border border-gray-300 rounded w-[200px] bg-white"
                >
                  {/* Default placeholder option */}
                  <option value="">
                    No style selected
                  </option>

                  {carouselInfo.slice(1).map((item, styleIndex) => (
                    <option
                      key={styleIndex}
                      value={item.alt}
                      disabled={selectedPackStyles.includes(item.alt) && selectedPackStyles[index] !== item.alt}
                    >
                      {item.alt}
                    </option>
                  ))}
                </select>
              ))}
            </div>
          )}

          <SizeSelector
            sizes={['5x5', '8x10']}
            onSizeChange={setSelectedSize}
          />
          <CopiesSelector maxCopies={10} isMobile={isMobile} onCopiesChange={setSelectedCopies} />
          <div className="upload-section mb-6">
            <p className="font-helv-neue">Upload your picture before adding to cart.</p>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={(event) => handleImageChange(event)}
              style={{ display: 'none' }}
            />
            <label
              htmlFor="file-upload"
              className="cursor-pointer add-to-cart btn btn-success text-[16px] text-off-black w-[180px] mt-2 font-helv-neue"
            >
              <FaPhotoVideo className="mr-2" />Choose Image
            </label>
            {uploadedImage.length > 0 && (
              <div className="my-4">
                {
                  uploadedImage.map((image_str, index) => (
                    image_str !== "" ?
                      (
                        <img
                          src={image_str}
                          alt="generated image"
                          className="h-32 object-contain shadow-lg border border-white border-8"
                        />) : null
                  ))}
              </div>)}
            {imageUploadInProgress && (
              <div className="text-xs text-gray-500">Uploading image... please wait</div>
            )}
          </div>

          <div className="textbox-container font-helv-neue">
            <label className="textbox-title" htmlFor="specialRequests">
              Special requests
            </label>
            <textarea
              id="specialRequests"
              className="textbox-input"
              rows="4"
              placeholder="Let us know any special requests you have for your image!"
              onChange={(event) => setSpecialRequests(event.target.value)}
            ></textarea>
          </div>

          <button className={`add-to-cart text-[16px] btn btn-md btn-success text-off-black py-2 px-0 w-[180px] flex flex-row font-helv-neue`}
            onClick={handleAddToCart}>
            <FaShoppingCart className="mr-2" />
            Add to cart
          </button>

          {/* Button that opens the modal */}
          <button onClick={handleCheckoutClick} className="btn btn-success btn-lg w-full custom-bg my-4 font-helv-neue">
            <FaCreditCard className="mr-2" />
            Checkout
          </button>

          <div className="flex flex-row w-full items-center justify-center space-x-4" >
            <img src={cards} className="w-[40px]"></img>
            <img src={applePayLogo} className="w-[40px]"></img>
            <img src={gpayLogo} className="w-[40px]"></img>
          </div>

          {/* ==================END OF PAGE CONTENT, MODAL DEFINITONS BELOW ========*/}

          <dialog ref={modalRef} className="modal">
            <div className="modal-box w-100 max-w-screen-2xl">
              <div className="py-4">
                {showCheckout && (
                  <EmbeddedCheckoutProvider stripe={Constants.stripePromise} options={options}>
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                )}
              </div>
              <div className="modal-action">
                <form method="dialog">
                  <button className="btn" onClick={handleCloseModal}>
                    Close
                  </button>
                </form>
              </div>
            </div>
          </dialog>

          {/* Proof Modal */}
          <dialog ref={howItWorksModalRef} className="modal">
            <div className="modal-box w-11/12 max-w-5xl font-helv-neue">
              <h3 className="text-2xl tracking-wide border-b">How it works</h3>
              <p className="py-4 text-md">
                Our expert team will craft custom photos just for you. We’ll email you a selection of proofs, and you pick the one(s) that resonates most. After you make your choice, we’ll move forward with printing.
                <br />
                <br />
                You’ll only pay the full price once you’re happy with your selected proof.
                <br />
                <br />
                Please allow up to 24 hours for us to create and send your proofs.
              </p>
              <div className="modal-action">
                <form method="dialog">
                  {/* if there is a button, it will close the modal */}
                  <button className="btn add-to-cart">Close</button>
                </form>
              </div>
            </div>
          </dialog>

          {/* Infographic Modal */}
          <>
            {infographicOpen && (
              <div className="fixed inset-0 bg-black opacity-50 z-10" />
            )}
            {isMounted && (
              <div
                style={{ height: `${trueHeight}px` }}
                className={`fixed z-20 inset-x-0 bottom-0 md:w-[50vw] overflow-y-auto flex bg-[#FDFDF2] items-center justify-center transition-transform duration-700 ${infographicOpen ? "translate-y-0" : "translate-y-full"
                  }`}
              >
                <div style={{ height: `${trueHeight}px` }} className="w-full overflow-y-auto">
                  <img
                    className="w-full h-auto"
                    src="https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/static_carousel_images%2Fholomi-how-to-v7.svg?alt=media&token=c8f61e21-9a48-45ab-b273-5169901f2e7f"
                    alt="Infographic"
                  />
                </div>
                <button
                  className="absolute top-4 right-4 text-black bg-gray-200 rounded-full p-3 hover:bg-gray-300 flex justify-center items-center"
                  onClick={toggleInfographicModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 md:h-8 md:w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </div>
            )}
          </>
          {
            !infographicOpen &&
            (
              <button
                className="fixed bottom-6 right-6 bg-gray-400 text-white opacity-70 rounded-full p-1 shadow-lg hover:bg-gray-700 transition-transform transform hover:scale-110 z-30"
                onClick={toggleInfographicModal}
              >
                <FaChevronCircleUp size={35} />
              </button>)
          }

        </div>
      </div >
      <div className="mx-auto font-poppins text-4xl mt-12 font-bold text-center"> Get your personalized prints today! Made individually by me.</div>
      <Guarantees />
      <div className="flex flex-row mb-4 ml-12 items-center">
        <div className="font-poppins text-4xl font-bold">Reviews</div>
        <div className="font-poppins text-2xl ml-2">({reviews.length})</div>
      </div>
      <ResponsiveCards />
    </div>
  );
};

export default AltHomePage;