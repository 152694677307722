import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';


const thinChevronStyle = {
  fontSize: '0.75em',
  opacity: 0.7,
  strokeWidth: 0.5, // This applies if the icon supports SVG paths
};

function ProductsDropdown() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);

  const handleNavigate = (path) => {
    if (location.pathname === path) {
      // If already on the page, trigger a reload
      window.location.reload();
    } else {
      // Otherwise, navigate to the new page
      navigate(path);
    }
  };


  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className="nav-links">
        <div className="flex items-center">
          Products <FaChevronDown style={thinChevronStyle} className="ml-1" />
        </div>
      </button>
      {isDropdownOpen && (
        <div
          className="absolute top-full mt-2 bg-white shadow-lg rounded p-4 w-48"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            padding: '10px',        // Increase the hover area around the dropdown
            marginTop: '0px',     // Offset margin to keep the dropdown position
            pointerEvents: 'auto',  // Ensures dropdown items are clickable
            zIndex: 10              // Keeps dropdown above surrounding elements
          }}
        >
          <button onClick={() => {handleNavigate('/');}} className="block w-full text-left px-4 py-2 hover:bg-gray-100">
            Holomi Proofs
          </button>
          <button className="block w-full text-gray-400 text-left px-4 py-2 cursor-default disabled">
            Image Generator (coming soon)
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductsDropdown;
