import React, { useState, useEffect } from 'react';
import './FadingBanner.css';

const FadingBanner = ({ messages, duration = 3000, fadeDuration = 1000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass('fade-out'); // Start fading out
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.length); // Move to next message
        setFadeClass('fade-in'); // Fade the next message in
      }, fadeDuration);
    }, duration);
    return () => clearInterval(interval);
  }, [messages.length, duration, fadeDuration]);

  return (
    <div className="fading-banner bg-[#FCF7F2]">
      <div className={`fading-text ${fadeClass} font-helv-neue text-off-black`}>
        {messages[currentIndex]}
      </div>
    </div>
  );
};

export default FadingBanner;
