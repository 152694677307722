import React, { useState, useEffect, useContext, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ConfirmationWidget from './ConfirmationWidget.js';
import holomiLogo from './images/holomi-logo.png';
import { OrderContext } from './OrderContext.js';
import * as Constants from './Constants.js';
import { log, isProd } from './Logger.js';
import './ConfirmationPage.css';

const ConfirmationPage = () => {
  const { state } = useLocation();
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const fetchCalled = useRef(false);

  const { orders, clearOrders } = useContext(OrderContext);

  useEffect(() => {
    if (!fetchCalled.current) {
      fetchCalled.current = true;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');
      log("Fetching session status");

      fetch(`${Constants.SERVER_BASE_URL}/session-status?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
          setOrderNumber(data.orderNumber);
        })
        .catch((err) => {
          console.error("Error fetching session status:", err);
          setStatus('error');
        });
    }
  }, []);

  useEffect(() => {
    if (status === 'complete') {
      log("Running handleClearOrders");
      handleClearOrders();
      fireFacebookPixel();
    }
  }, [status]); // Only run when `status` changes to 'complete'

  const handleClearOrders = async () => {
    try {
      await clearOrders();
    } catch (error) {
      console.error('Error clearing orders:', error);
    }
  };

  const fireFacebookPixel = () => {
    const pixelFiredKey = `fb_pixel_fired_${orderNumber}`;
    // Check if the pixel has already been fired for this order
    if (!localStorage.getItem(pixelFiredKey)) {
      // Fire the Facebook Pixel
      log("Firing Facebook Pixel");
      if (window.fbq && isProd()) {
        window.fbq('track', 'Purchase', {
          currency: 'USD', // Replace with your currency if needed
          value: orders.length * 500, // Replace with the order value
          orderNumber, // Optional: include order number for debugging
        });
      } else {
        log('Facebook Pixel is not initialized');
      }
      // Mark the pixel as fired
      localStorage.setItem(pixelFiredKey, 'true');
    } else {
      log("Facebook Pixel already fired for this order");
    }
  };

  // Handle different statuses
  if (status === 'open') {
    alert("Something went wrong with your order. Please try again.");
    return <Navigate to="/" />;
  }

  if (status === 'complete') {
    return (
      <ConfirmationWidget
        holomiLogo={holomiLogo}
        customerEmail={customerEmail}
        orderNumber={orderNumber}
      />
    );
  }

  return <div>Loading...</div>;
};

export default ConfirmationPage;
