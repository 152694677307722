import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const MenuDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);


  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };


  const handleNavigation = (destination) => {
    if (location.pathname === destination) {
      // Trigger a reload if the destination is the current route
      window.location.reload();
    } else {
      navigate(destination); // Navigate to the destination if it's a different route
    }
  };

  return (
    <div className="block menu-drawer">
      {/* Menu Icon */}
      <button
        onClick={toggleDrawer}
        className="menu-button text-off-black border-off-black rounded-md flex items-center justify-center hover:bg-gray-700 transition-colors"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>

      {/* Drawer */}
      {isMounted && (
        <div
          className={`fixed top-0 left-0 h-full bg-gray-800 text-white w-64 transform ${isOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-300 shadow-lg z-40`}
        >
          <button
            onClick={closeDrawer}
            className="absolute top-4 right-4 text-3xl font-bold"
          >
            ✕
          </button>
          <ul className="mt-20 space-y-1 pl-4 text-lg font-medium">
            <li onClick={() => {handleNavigation("/")}} className="hover:text-gray-300 cursor-pointer">Shop</li>
            <li onClick={() => {handleNavigation("/faq")}} className="hover:text-gray-300 cursor-pointer">FAQ</li>
          </ul>
        </div>)}

      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-30"
          onClick={closeDrawer}
        />
      )}
    </div>
  );
};

export default MenuDrawer;
