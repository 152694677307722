import React, { useEffect, useContext, useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import './Cart.css'; // Custom CSS for styling the cart drawer
import { OrderContext } from './OrderContext'; // Import the OrderContext
import * as Constants from './Constants.js'


const orderSizeToLabel = (size) => {
  return size + " Print";
}

const modeToLabel = (mode) => {
  if (mode === Constants.GYO) {
    return "Made with holomi generator";
  }
  if (mode === Constants.UYO) {
    return "Self-uploaded";
  }
  if (mode === Constants.LITU) {
    return "We generate for you";
  }
}



const CartDrawer = ({ checkoutFunc, isCartOpen, toggleCartDrawer }) => {
  const { orders, removeOrder } = useContext(OrderContext);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOutsideClickCart = (event) => {
    if (!event.target.closest('.cart-drawer') && !event.target.closest('.cart-icon')) {
      toggleCartDrawer(false);
    }
  };

  const handleRemoveClick = (id) => {
    setSelectedOrderId(id);
    setShowModal(true);
  };

  const confirmRemove = async () => {
    await removeOrder(selectedOrderId);
    setShowModal(false);
  };

  useEffect(() => {
    if (isCartOpen) {
      document.addEventListener('click', handleOutsideClickCart);
    } else {
      document.removeEventListener('click', handleOutsideClickCart);
    }
    return () => document.removeEventListener('click', handleOutsideClickCart);
  }, [isCartOpen]);

  return (
    <div className={`cart-drawer ${isCartOpen ? 'open' : ''} font-helv-neue`}>
      <div className="cart-content">
        <button className="close-cart-button" onClick={() => toggleCartDrawer(false)}>✕</button>
        <h3 className="font-bold text-2xl">Your Cart</h3>
        {orders.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          orders.map((order, index) => (
            <div key={index} className="c-cart-item font-baloo">
              <button className="c-remove-button" onClick={() => handleRemoveClick(order.id)}>✕</button>
              <p className="c-cart-item-name">{orderSizeToLabel(order.size)}</p>
              <p className="c-cart-item-type">{order.style}</p>
              <div className="flex flex-row justify-between items-center">
                <p className="c-cart-item-price text-soft-pink text-2xl">$5</p>
                <img className="w-[60px]" src={order.imagePairs[0]} alt="Order preview" />
              </div>
            </div>
          ))
        )}
        <button onClick={checkoutFunc} className="btn btn-success btn-lg w-full custom-bg my-4 font-helv-neue">
          Checkout
        </button>
      </div>
      {showModal && (
        <div className="c-modal-overlay font-helv-neue">
          <div className="c-modal">
            <p className="mb-2">Are you sure you want to remove this item from your cart?</p>
            <button onClick={confirmRemove} className="c-confirm-button">Confirm</button>
            <button onClick={() => setShowModal(false)} className="c-cancel-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

const CartIcon = ({ toggleCartDrawer }) => {
  const { orders } = useContext(OrderContext);
  return (
    <div className="cart-icon-container cursor-pointer relative" onClick={toggleCartDrawer}>
      <FaShoppingCart className="cart-icon" />
      {orders.length > 0 && (
        <div className="order-count-indicator bg-soft-pink text-off-black rounded-full w-[15px] h-[15px] flex items-center justify-center absolute -bottom-2 -right-2 text-xs">
          {orders.length}
        </div>
      )}
    </div>
  );
};

export { CartDrawer, CartIcon };