import React, { Suspense, lazy } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { OrderProvider } from './components/OrderContext.js';
import ConfirmationPage from './components/ConfirmationPage.js';
import ConfirmationPageApplePay from './components/ConfirmationPageApplePay.js';
import ClearLocalStorage from './components/generator-flow/ClearLocalStorage.js';
import AltHomePage from './components/altHomePage.js';
import FAQPage from './components/faq.js';
import AnalyticsTest from './components/analyticsTest.js';
import {Carousel, ResponsiveCards} from './components/ReviewSwiper.js';
import * as Constants from './components/Constants.js';
// Imports for Apple/Google pay elements
import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = Constants.stripePromise;

const Generator = lazy(() => import('./components/generator-flow/Generator.js'));
const ProofPage = lazy(() => import('./components/ProofPage.js'));
const ConfirmationPageProofs = lazy(() => import('./components/ConfirmationPageProofs.js'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Elements stripe={stripePromise}>
        <OrderProvider>
          <Router basename="/">
            <Routes>
              <Route path="/generate/generateyourown" element={<Generator />} />
              <Route path="/confirmation" element={<ConfirmationPage />} />
              <Route path="/confirmation-apple-pay" element={<ConfirmationPageApplePay />} />
              <Route path="/confirmation-proof" element={<ConfirmationPageProofs />} />
              <Route path="/" element={<AltHomePage />} />
              <Route path="/faq" element={<FAQPage />} />

              <Route path="/clear-local-storage" element={<ClearLocalStorage />} />
              <Route path="/proof" element={<ProofPage />} />
              <Route path="/carousel" element={<Carousel />} />
              <Route path="/responsive-cards" element={<ResponsiveCards />} />

              <Route path="/analytics" element={<AnalyticsTest />} />
            </Routes>
          </Router>
        </OrderProvider>
      </Elements>
    </Suspense>
  );
}

export default App;