import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useRef, useState } from 'react';
import { FiRepeat } from 'react-icons/fi'; // Example from react-icons
import { Navigation, Pagination } from 'swiper/modules';
import ReactRating from 'react-rating';

import './ReviewSwiper.css'; // Optional styling file

const ReviewCard = ({ text, author, stars, media, style }) => {
    const [showTrans, setShowTrans] = useState(true);
    return (
        <div className="review-card font-poppins h-full">
            <div className="rounded-lg overflow-hidden review-media w-auto relative flex items-center justify-center">
                {media?.type === "video" && (
                    <video
                        className="absolute w-full h-full object-cover"
                        src={media.src}
                        autoPlay
                        loop
                        muted
                    />
                )}
                {media?.type === "image" && (
                    <>
                        {media?.src && (
                            <img
                                className={`absolute w-full h-full object-cover transition-opacity duration-300 ${showTrans ? "opacity-0 pointer-events-none" : "opacity-100"}`}
                                src={media.src}
                                alt="review"
                            />
                        )}
                        {media?.trans && (
                            <img
                                className={`absolute w-full h-full object-cover transition-opacity duration-300 ${showTrans ? "opacity-100" : "opacity-0 pointer-events-none"}`}
                                src={media.trans}
                                alt="review"
                            />
                        )}
                    </>
                )}
            </div>

            <ReactRating
                className="mt-4"
                initialRating={stars}
                readonly
                emptySymbol={<span className="text-gray-300 text-xl">★</span>}
                fullSymbol={<span className="text-black text-xl">★</span>}
            />
            <div className="flex flex-row w-full justify-between">
                <p className="review-author text-2xl font-bold">{author}</p>
                {media?.type == "image" && media?.src && media?.trans &&
                    <button
                        className="p-2 bg-gray-100 rounded-full hover:bg-gray-200"
                        onClick={() => { setShowTrans(!showTrans) }}
                    >
                        <FiRepeat
                            size={24}
                            className={`transform transition ${showTrans ? 'rotate-180' : ''
                                }`}
                        />
                    </button>
                }
            </div>
            <p className="italic">{style}</p>
            <p className="review-text mt-4">{text}</p>
        </div>
    );
};

const reviews = [
    {
        id: 1,
        text: "The print i received looks great. it really captures the way I look while also tranforming the picture in a really cool way.\
        I put it on my wall and it flips every time I walk by it lol",
        author: "Josh D.",
        media: {
            type: "image",
            src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2F462542328_563280289617905_2199314832772519473_n.webp?alt=media&token=7208a1c7-90bd-4320-be59-49315b7d2c35",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Fjosh_final.webp?alt=media&token=eeac0e29-3cd0-4b40-9d47-ea21c7ada98a"
        },
        style: "Ani-you",
        stars: 5
    },
    {
        id: 2, text: "my girlfriend loved it, thanks!", author: "Marcus P.",
        media: {
            type: "image",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Fmarcus.webp?alt=media&token=4b6dab96-1ed4-4377-9350-8f770108771d"
        },
        style: "Ani-you",
        stars: 5
    },
    {
        id: 2, text: "i love this!! the style i got was so cute and came out much better than i thought it would!! thank u!", author: "Tani D.",
        media: {
            type: "image",
            src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Ftanie2.webp?alt=media&token=e5993903-8ee0-498e-9bb0-783f3f565d47",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Ffinal_output.webp?alt=media&token=a8209919-b391-47ee-b499-7f3f1dbcfa76"
        },
        style: "Pixar Style",
        stars: 5
    },
    {
        id: 2, text: "It's so so so cute! Thank you for making my dog look amazing!", author: "Sherry T.",
        media: {
            type: "image",
            src:"https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Fbase.webp?alt=media&token=a212bebd-e7d0-49f2-9c33-6403158182b3",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Foutput.webp?alt=media&token=e9700eee-feec-4c5f-bbc4-68ffc81503e4"
        },
        style: "Posh Sketch",
        stars: 5
    },
    {
        id: 2, text: "Came across this from instagram. Thought, \"I just got married so why not\" and bought it impulsively to commemorate my wedding. It came out great, and my wife loves it too. Thanks!", author: "Paul S.",
        media: {
            type: "image",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Fpixar_wedding_final_mj_output.webp?alt=media&token=6a485797-f7d4-476a-b669-42b0cc5779fa"
        },
        style: "Pixar Style",
        stars: 5
    },
    {
        id: 2, text: "great, gave it to my girlfriend as a christmas present", author: "Daryl D.",
        media: {
            type: "image",
            src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Fbase_darien.webp?alt=media&token=316b7d66-3413-4312-b4b3-f640ab642fe5",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Ftrans.webp?alt=media&token=d8a9aafe-a193-4ce3-96ba-ab6c38a7b9d9"
        },
        style: "Ani-you",
        stars: 5
    },
    {
        id: 2, text: "This is such a cool gift! The flip effect is really unique, and the artist captured my looks pretty well.", author: "Albin L.",
        media: {
            type: "image",
            src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Falbin_src.webp?alt=media&token=3b2c8dc9-eca1-41c6-a9d7-85bbe4581743",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Falbin_trans.webp?alt=media&token=f2abcdf7-ccb1-4c38-bbee-a80c8d9f614f"
        },
        style: "Ani-you",
        stars: 5
    },
    {
        id: 2, text: "cool! my friends loved it", author: "Hinano Y.",
        media: {
            type: "image",
            src: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Fhinano_base2.webp?alt=media&token=f8c1c832-7310-4103-ae67-f15b38f35568",
            trans: "https://firebasestorage.googleapis.com/v0/b/holomi-21cb0.firebasestorage.app/o/review_assets%2Fhinano_mj.webp?alt=media&token=edda6cde-d3b3-4553-a96d-2ef5393214b1"
        },
        style: "Ani-you",
        stars: 5
    },
];


const Carousel = () => {
    const swiperRef = useRef(null);

    return (
        <div
            className={`w-[80vw] mx-auto`}
        >
            <Swiper
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                breakpoints={{
                    640: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                }}
                loop={true}
                allowTouchMove={false}
            >
                {reviews.map((review) => (
                    <SwiperSlide key={review.id}>
                        <ReviewCard
                            text={review.text}
                            author={review.author}
                            style={review.style}
                            media={review.media}
                            stars={review.stars}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};


const ResponsiveCards = () => {
    return (
        <div className="mx-auto px-4 w-[90vw]">
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {reviews.map((review) => (
                    <div
                        key={review.id}
                        className="p-2 flex"
                    >
                        <div className="flex flex-col w-full h-full">
                            <ReviewCard
                                text={review.text}
                                author={review.author}
                                style={review.style}
                                media={review.media}
                                stars={review.stars}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};



export {Carousel, ResponsiveCards, reviews};