import React from 'react';
import { Link } from 'react-router-dom';

const ConfirmationWidget = ({ holomiLogo, customerEmail, orderNumber }) => {

  return (
    <div className="flex flex-row w-screen h-screen">
      <div className="cp-confirmation-page mx-auto my-auto">
        <img src={holomiLogo} alt="Logo" className="cp-logo mx-auto" />
        <div className="cp-confirmation-content">
          <h2>Thank you for your order!</h2>
          <p>Your order number is: <strong>{orderNumber}</strong></p>
          <p>If you ordered a proof, it will be sent to: <strong>{customerEmail}</strong></p>
          <Link to="/" className="cp-home-button">Return to Home</Link>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationWidget;
