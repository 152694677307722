import { loadStripe } from '@stripe/stripe-js';

const LITU = 'leaveittous'
const GYO = 'generateyourown'
const UYO = 'uploadafterimage'

function getPrice(size) {
  if (size === '5x5') {
    return "29.99"
  }
  if (size === '8x10') {
    return "42.99"
  }
  return "Unknown";
}

const PROOF_FEE = 3.00;

console.log("Environment: ", process.env.REACT_APP_ENVIRONMENT);
console.log("BASE_URL: ", process.env.REACT_APP_SERVER_BASE_URL);
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_ENVIRONMENT === "test" ? 'pk_test_51QGMpVGGk3dbgPJxp9N3WBsGBVQg0Zl1AYTjUFb4JOEb68RgHQEb6F4Klc0MtgCrOWaAGyxbhtLA3IcqUQLdAlzb00agA8wUBm' : 'pk_live_51QGMpVGGk3dbgPJxdI9UOhE4ylJO0KKIg992XaoshlFOAKLedYdqyLbcB1rlqGe0ZvjY6YefIfGo8jZZPPJFU4wc00wKJaiAAi';
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY); // Replace with actual Stripe public key


const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL || "";

const NUM_PROOFS = "Six";

export { LITU, GYO, UYO, getPrice, PROOF_FEE, SERVER_BASE_URL, stripePromise, NUM_PROOFS };