import React, { useState, useRef, useEffect } from "react";
import {log} from './Logger.js';

const tagColors = {
  Anime: "#FAD5E5", // Soft pink
  People: "#A3D8F4", // Light blue
  Pets: "#D7F9C4", // Pale green
  Poses: "#FFE8A3", // Soft yellow
  Abstract: "#E1D4F9", // Lavender
  "3D": "#FFC7CE", // Light coral
  Portrait: "#FBD1BD", // Light rose
  "Variety Pack": "#F8D7E3", // Pastel pink
  Recommended: "#F6E3B4", // Light gold
  Default: "#E2E8F0", // Light grey
  Faces: "#FFCDD2", // Light red
};

const tagDescriptions = {
  Anime: "Based on anime style",
  People: "Suited for crafting visuals of people",
  Pets: "Suited for crafting visuals of pets",
  Poses: "Recommended for representing photographs not necessarily taken from the front",
  Abstract: "A style that removes a lot of detail",
  "3D": "A 3D-based style",
  Portrait: "Recommended for head-on photographs",
  "Variety Pack": "You select different styles!",
  Faces: "Not recommended for faces.",
};

const TagNegate = {
  Faces: true,
};

const Tag = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const tagRef = useRef(null);

  useEffect(() => {
    if (isHovered && tagRef.current) {
      
      const tagRect = tagRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      let top = tagRect.top - 28; // Position above tag
      let left = tagRect.left;
      log("Tag.js" + "top: " + top + " left: " + left);

      // Ensure tooltip stays within viewport horizontally
      if (tagRect.left + tagRect.width > viewportWidth - 16) {
        left = viewportWidth - tagRect.width - 16;
      } else if (tagRect.left < 16) {
        left = 16;
      }

      setTooltipStyle({ top: `${top}px`, left: `${left}px`, position: 'fixed' });
    }
  }, [isHovered]);

  return (
    <div
      className="relative flex flex-col items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Tooltip */}
      {isHovered && tagDescriptions[text] && (
        <div
          className="absolute bg-gray-800 text-white text-xs px-2 py-1 rounded-lg shadow-md whitespace-nowrap"
          style={{
            ...tooltipStyle,
            zIndex: 10,
            maxWidth: "300px",
            textWrap: "wrap",
          }}
          aria-label={tagDescriptions[text]}
        >
          {tagDescriptions[text]}
        </div>
      )}

      {/* Tag */}
      <div
        className="rounded-lg px-3 py-1 text-sm font-helv-neue mr-2 mb-2"
        ref={tagRef}
        style={{
          backgroundColor: tagColors[text] || tagColors.Default,
          color: "#333333", // Ensure contrast
          textDecoration: TagNegate[text] ? "line-through" : "none",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default Tag;
