import { openDB } from 'idb';

const DB_NAME = 'ordersDB';
const STORE_NAME = 'orders';

export const getDB = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    },
  });
};

export const getAllOrders = async () => {
  const db = await getDB();
  return db.getAll(STORE_NAME);
};

export const addOrUpdateOrder = async (order) => {
  const db = await getDB();
  return db.put(STORE_NAME, order);
};

export const deleteOrder = async (id) => {
  const db = await getDB();
  return db.delete(STORE_NAME, id);
};

export const clearOrdersStore = async () => {
  const db = await getDB();
  return db.clear(STORE_NAME);
};
