import * as Constants from './Constants.js';

export const log = (message) => {
  if(Constants.SERVER_BASE_URL.includes("localhost")) {
    console.log(message);
  }
}

export const isProd = () => {
  return !Constants.SERVER_BASE_URL.includes("localhost");
}