import React from 'react';
import Banner from './Banner.js'
import { CartDrawer } from './Cart.js';

const FAQPage = () => {
  const faqData = [
    {
      question: "How does this work?",
      answer: `<b>1.</b> Choose your preferred style and checkout.<br/><br/>
               <b>2.</b> Our team will craft high-quality custom photos for you.<br/><br/>
               <b>3.</b> We’ll email you a set of samples before we ship. By default we will ship a day after the samples are sent, but you can choose to cancel at that point for a refund.<br/><br/>
               <b>4.</b> Wait for your prints to arrive!`
    },
    {
      question: "How much is the refund?",
      answer: `We charge $10 for labor per style, so the refund is $10 less than the total cost of each style.`
    },
    {
      question: "Do you have any tips for selecting a style?",
      answer: `Different styles look better with different types of photos. Please check out the tags under each style to see if your picture is a good fit.<br/><br/>
              Keep the complexity of pictures low. That means: not too many people (~3 is fine), not too much going on in the photo, and we should be able to tell what's going on. These are just suggestions,
              and we can work with most pictures. If you don't like it, you can request the refund.`
    },
    {
      question: "Can I see my photo in advance?",
      answer: `Yes, we will send you sample photos a day before we ship. At that point you can choose to refund. <br/><br/> 
      
      We try to generate quality proofs, but understand sometimes they may not match your expectations.`
    },
    {
      question: "Why is there a labor fee?",
      answer: "Surprisingly, it takes some effort to generate a picture of good quality. We have to charge a small cost for that labor."
    },
    {
      question: "How long does proof generation take?",
      answer: "Please allow around 24 hours for us to create and send your proofs. We’ll notify you as soon as they’re ready for review. Note, during busy times it may take longer than expected. We'll refund your order if we can't get to it."
    },
    {
      question: "How long before I get my prints?",
      answer: "After proof confirmation, please allow 5-7 days to receive your print. We do not currently have priority shipping available, but are working on it."
    },
    {
      question: "Can I do 10x8 instead of 8x10 / Can I change the direction of flipping?",
      answer: "Yes! Please let us know in the request box at checkout. Otherwise, the default is 8 inches wide by 10 inches tall, and the default flip direction is left-to-right."
    },
    {
      question: "What if I have questions about the styles?",
      answer: "Our team is here to help! Feel free to contact us with any questions about styles or customization, and we’ll guide you through the options."
    },
    {
      question: "How can I get in touch?",
      answer: "Please contact us at <a href='mailto:support@holomi.us' style='color: blue; text-decoration: underline;'>support@holomi.us</a>"
    }
  ];

  return (
    <div className="bg-container bg-white">
      <Banner />
      <div className="max-w-4xl mx-auto p-6 pt-0 font-helv-neue">
        <div className="rounded">
          <h1 className="text-5xl font-bold mb-4 text-center text-gray-800 text-soft-pink font-baloo">FAQ</h1>
          <div className="space-y-6">
            {faqData.map((faq, index) => (
              <div key={index} className="faq-item bg-white border border-gray-200 rounded-lg shadow-md p-4">
                <h3 className="font-medium text-xl text-gray-800 mb-4 pb-2 border-b">
                  {faq.question}
                </h3>
                <p
                  className="text-gray-600 text-lg"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                ></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
