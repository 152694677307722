import React, { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { analytics } from "./firebaseConfig.js";

const NotificationPage = () => {
  useEffect(() => {
    // Initialize Firebase Analytics and log the event
    logEvent(analytics, "notification_received");
  }, []);

  return (
    <div>
      <h1>Notification Page</h1>
      <p>This page logs a notification received event.</p>
    </div>
  );
};

export default NotificationPage;