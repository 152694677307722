import React, { createContext, useState, useEffect } from 'react';
import { getAllOrders, addOrUpdateOrder, deleteOrder, clearOrdersStore } from './idbUtils.js';
import { log } from './Logger.js';

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    // Load initial orders from IndexedDB
    const loadOrders = async () => {
      const storedOrders = await getAllOrders();
      setOrders(storedOrders);
    };

    loadOrders();
  }, []);

  // Function to add or replace an order with the same ID
  const addOrder = async (newOrder) => {
    log('AddOrder called with:', newOrder);

    setOrders((prevOrders) => {
      const existingOrderIndex = prevOrders.findIndex((order) => order.id === newOrder.id);

      if (existingOrderIndex !== -1) {
        const updatedOrders = [...prevOrders];
        updatedOrders[existingOrderIndex] = newOrder;
        return updatedOrders;
      } else {
        return [...prevOrders, newOrder];
      }
    });

    // Persist to IndexedDB
    await addOrUpdateOrder(newOrder);
  };

  const removeOrder = async (orderId) => {
    setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));

    // Remove from IndexedDB
    await deleteOrder(orderId);
  };

  const updateOrder = async (orderId, updatedProperties) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, ...updatedProperties } : order
      )
    );

    // Update in IndexedDB
    const updatedOrder = orders.find((order) => order.id === orderId);
    if (updatedOrder) {
      await addOrUpdateOrder({ ...updatedOrder, ...updatedProperties });
    }
  };

  const clearOrders = async () => {
    setOrders([]);
    await clearOrdersStore();
  };

  return (
    <OrderContext.Provider
      value={{ orders, addOrder, updateOrder, removeOrder, clearOrders }}
    >
      {children}
    </OrderContext.Provider>
  );
};
