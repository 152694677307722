import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import { carouselInfo } from './imageSelectionConstants.js';
import './MediaGallery.css';
import { log } from './Logger.js';

const getVideoQuality = () => {
  if (navigator.connection && navigator.connection.effectiveType) {
    const effectiveType = navigator.connection.effectiveType;
    if (effectiveType === '5g' || effectiveType == '4g' || effectiveType =="3g") {
      return 'high';
    } 
  }
  return 'low';
};

const MediaGallery = ({ activeMediaIndex }) => {
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);
  const [containerHeight, setContainerHeight] = useState(window.innerWidth <= 768 ? window.innerWidth : 600);
  const [videoIsLoaded, setVideoIsLoaded] = useState(false);
  const [videoQuality, setVideoQuality] = useState(getVideoQuality());

  const videoRefs = useRef([]);
  const activeMediaRef = useRef(null);
  const mediaContainerRef = useRef(null);

  useEffect(() => {
    const quality = getVideoQuality();
    log('Video quality on render: ' + quality);
    setVideoQuality(quality);
  }, []);

  const calculateContainerHeight = useCallback(() => {
    log('calculateContainerHeight');

    if (!mediaContainerRef.current) {
      setContainerHeight(maxHeight);
      return;
    }


    const isMobile = window.innerWidth <= 768;
    const maxHeight = isMobile ? window.innerWidth : 600;
    let height = maxHeight;


    const currentMedia = carouselInfo[activeMediaIndex]?.media[activeThumbnailIndex];
    if (currentMedia?.type === 'video') {
      // Use a more robust way to get the thumbnail image
      const thumbnailImage = mediaContainerRef.current.querySelector(`.media-item:nth-child(${activeMediaIndex + 1}) .video-thumbnail`);
      thumbnailImage.onload = () => {
        let height = thumbnailImage.offsetHeight || maxHeight;
        log('Video thumbnail height: ' + height);
        if (currentMedia.translate) {
          const translate = currentMedia.translate.split(' ')[1].match(/\d+/g);
          height -= Number(translate) / 100.0 * height
        }
        setContainerHeight(Math.min(height, maxHeight));
      };
      height = thumbnailImage?.offsetHeight || maxHeight;
      if (currentMedia.translate) {
        const translate = currentMedia.translate.split(' ')[1].match(/\d+/g);
        height -= Number(translate) / 100.0 * height
      }
    } else if (currentMedia?.type === 'slider') {
      const sliderImage = mediaContainerRef.current.querySelector(`.media-item:nth-child(${activeMediaIndex + 1}) .before-after-slider img`);
      if (sliderImage) {
        // Attach an onload event listener
        sliderImage.onload = () => {
          const height = sliderImage.offsetHeight || maxHeight;
          log('Slider image height: ' + height);
          setContainerHeight(Math.min(height, maxHeight));
        };
        height = sliderImage.offsetHeight || maxHeight;
      } else {
        height = currentMedia.imgHeight || maxHeight;
      }
    }
    log(height);
    setContainerHeight(Math.min(height, maxHeight));


  }, [activeMediaIndex, activeThumbnailIndex]);


  useEffect(() => {
    log("ActiveMediaIndex effect")
    setActiveThumbnailIndex(0);
    setVideoIsLoaded(false);
  }, [activeMediaIndex]);

  useEffect(() => {
    // Calculate height when activeMediaIndex or activeThumbnailIndex changes
    calculateContainerHeight();

  }, [activeMediaIndex, activeThumbnailIndex]);

  // Resize listener
  useEffect(() => {
    const handleResize = () => {
      calculateContainerHeight();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateContainerHeight]);

  const handleThumbnailClick = (index) => {
    setActiveThumbnailIndex(index);
  };

  const setVideoAttributes = (video) => {
    if (video) {
      video.defaultMuted = true;
      video.muted = true;
      video.playsInline = true;
    }
  };

  return (
    <div className="media-gallery px-2 mb-2 relative">
      <div
        ref={mediaContainerRef}
        className="media-container relative"
        style={{ width: '100%', overflow: 'hidden', height: containerHeight }}
      >
        {carouselInfo.map((mediaCollection, mediaIndex) => (
          <div
            className="media-item"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              visibility: activeMediaIndex === mediaIndex ? 'visible' : 'hidden',
              zIndex: activeMediaIndex === mediaIndex ? 2 : 1,
              pointerEvents: activeMediaIndex === mediaIndex ? 'auto' : 'none',
            }}
          >
            {mediaCollection.media.map((media, thumbnailIndex) => (
              <div
                ref={
                  activeThumbnailIndex === thumbnailIndex &&
                    activeMediaIndex === mediaIndex
                    ? activeMediaRef
                    : null
                }
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  visibility:
                    activeThumbnailIndex === thumbnailIndex &&
                      activeMediaIndex === mediaIndex
                      ? 'visible'
                      : 'hidden',
                  zIndex:
                    activeThumbnailIndex === thumbnailIndex &&
                      activeMediaIndex === mediaIndex
                      ? 2
                      : 1,
                  pointerEvents:
                    activeThumbnailIndex === thumbnailIndex &&
                      activeMediaIndex === mediaIndex
                      ? 'auto'
                      : 'none',
                }}
              >
                {media.type === 'video' ? (
                  <div>
                    {mediaIndex === activeMediaIndex && (
                      <video
                        ref={(el) => {
                          videoRefs.current[mediaIndex] = el;
                          setVideoAttributes(el);
                        }}
                        loop
                        autoPlay
                        className="rounded w-full h-full object-cover absolute"
                        onCanPlay={() => setVideoIsLoaded(true)}
                        style={{ translate: media.translate || 'none' }}
                      >
                        <source src={media.src[videoQuality]} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <img
                      src={media.thumbnail}
                      alt="Media"
                      className={`video-thumbnail rounded w-full h-full object-cover ${videoIsLoaded ? 'opacity-0' : ''
                        }`}
                      style={{
                        position: 'relative',
                        translate: media.translate || 'none',
                      }}
                    />
                  </div>
                ) : media.type === 'image' ? (
                  <img
                    src={media.src}
                    alt="Media"
                    className="main-image rounded w-full object-cover"
                  />
                ) : media.type === 'slider' ? (
                  <div className="w-full h-full object-cover">
                    <ReactBeforeSliderComponent
                      firstImage={{ imageUrl: media.src }}
                      secondImage={{ imageUrl: media.trans }}
                    />
                  </div>
                ) : (
                  <div>No media available</div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="thumbnail-row flex flex-row overflow-x-auto mt-2">
        {carouselInfo[activeMediaIndex].media.map((item, thumbnailIndex) => (
          <div
            key={thumbnailIndex}
            className={`thumbnail ${activeThumbnailIndex === thumbnailIndex ? 'active' : ''
              } gallery-thumbnail ${thumbnailIndex !== 0 ? 'ml-2' : ''} rounded cursor-pointer transform transition duration-200 hover:opacity-80 active:scale-95`}
            onClick={() => handleThumbnailClick(thumbnailIndex)}
          >
            <img
              src={item.thumbnail || item.src}
              alt="Thumbnail"
              className="thumbnail-image w-full h-full object-cover rounded-md"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaGallery;
