const ClearLocalStorage = () => {
    const clearLocalStorage = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <button onClick={clearLocalStorage}>Clear Local Storage</button>
    );
}

export default ClearLocalStorage;