import React, { useState } from 'react';

const CopiesSelector = ({ defaultCopies = 1, maxCopies = 10, isMobile, onCopiesChange }) => {
  const [selectedCopies, setSelectedCopies] = useState(defaultCopies);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleCopiesChange = (copies) => {
    setSelectedCopies(copies);
    onCopiesChange?.(copies); // Call the callback if provided
  };

  const renderDropdown = () => (
    <div className="relative inline-block dropdown">
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="p-2 w-[65px] border border-2 border-off-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex justify-between items-center"
      >
        <span>{selectedCopies}</span>
        <span>▼</span>
      </button>
      {isDropdownOpen && (
        <div className="absolute w-[65px] mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto z-10">
          {Array.from({ length: maxCopies }, (_, i) => i + 1).map((copies) => (
            <div
              key={copies}
              className={`p-2 cursor-pointer hover:bg-indigo-100 ${
                selectedCopies === copies ? 'bg-indigo-200' : ''
              }`}
              onClick={() => {
                handleCopiesChange(copies);
                setIsDropdownOpen(false);
              }}
            >
              {copies}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const renderSelect = () => (
    <select
      value={selectedCopies}
      onChange={(e) => handleCopiesChange(Number(e.target.value))}
      className="w-[65px] bg-white p-2 border border-off-black focus:outline-none focus:ring-2 focus:ring-indigo-500"
    >
      {Array.from({ length: maxCopies }, (_, i) => i + 1).map((copies) => (
        <option key={copies} value={copies} className="p-2">
          {copies}
        </option>
      ))}
    </select>
  );

  return (
    <div className="copies-selection font-helv-neue">
      <h4 className="text-lg font-semibold mb-2">Number of copies</h4>
      {isMobile ? renderSelect() : renderDropdown()}
    </div>
  );
};

export default CopiesSelector;